@import url(https://use.typekit.net/dqb7dxp.css);

// Override default variables before the import
$green: #0a5;
$teal: #078;

$body-bg: #f4f4f4;
$body-color: #444;
$link-color: $teal;
$yiq-text-dark: #444;

$primary: $teal;
$secondary: #000;

$input-focus-border-color: darken($primary, 5%);
$custom-control-indicator-active-bg: $input-focus-border-color; // darken($primary, 35%);
$custom-range-thumb-active-bg: $input-focus-border-color; // darken($primary, 35%);

$headings-font-family: century-gothic, -apple-system, BlinkMacSystemFont,
  'Segoe UI', Roboto, 'Helvetica Neue', Arial, 'Noto Sans', 'Liberation Sans',
  sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',
  'Noto Color Emoji';

// Import Bootstrap and its default variables
@import '~bootstrap/scss/bootstrap.scss';
