:root {
  --font-size__base: 1rem;
  --font-size__small: 0.85rem;
  --font-size__h1: 1.85rem;
}

html[host='osl'] {
  font-size: 14px;
}

html {
  font-size: 12px;
  transition: font-size 0.2s;
}

@media (min-width: 360px) {
  html {
    font-size: 14px;
  }
}

@media screen and (min-width: 600px) {
  html {
    font-size: 16px;
  }
}

/* GENERAL */
body {
  min-width: 270px;
}

#root .row {
  padding: 0.1rem;
}

.notInIframe #searchBar {
  font-size: 16px;
}

html #root {
  max-width: 600px;
  margin: 0 auto;
}

#root .title {
  margin: 1rem 0;
}

#root footer {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 1.5rem;
  padding-left: 0.75rem;
  color: rgba(0, 0, 0, 0.4);
  font-size: var(--font-size__small);
  text-align: left;
}

.faCaretDown .fa-caret-right {
  transform: rotate(90deg);
}

#root .btn-link {
  text-decoration: none;
}

#root .card .meta span {
  margin-right: 0;
}

#root .ui .grid > .row:before {
  box-shadow: none;
}

#root .card .meta,
#root .cards > .card .meta {
  font-size: 1rem;
}

#root .ui.form input[type='text'] {
  border-right-width: 0;
  background: #fff;
  font-size: 16px;
}

#root .card:not(.start-page) {
  animation: opacity-animation 2s;
  -webkit-animation: opacity-animation 2s;
  -moz-animation: opacity-animation 2s;
  margin: 0 0 1rem 0;
}

#root {
  padding: 0 1rem;
}

#root .link {
  color: #078;
  cursor: pointer;
}

#root .header .grid {
  width: 100%;
}

#root .column {
  margin-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  padding-top: 0;
}

.ui.card > .content,
.ui.cards > .card > .content {
  font-size: 1rem;
}

.ui.card > .extra,
.ui.cards > .card > .extra {
  font-size: var(--font-size__small);
}

/* ACCORDION */

#root .lemma-content .accordion .title {
  font-family: inherit;
  color: #777;
  padding-bottom: 0;
}

#root .lemma-content .accordion .content {
  padding: 1rem;
  border: 2px solid #eee;
  border-radius: 4px;
  animation: 1s opacity-animation;
}

#root .lemma-content .accordion .content .title {
  padding-top: 0.1rem;
  color: rgba(0, 0, 0, 0.68);
}

#root .accordion .list li:before {
  color: #777;
  font-size: 10px;
  font-size: 0.67rem;
}

#root .accordion-content-title:first-child {
  margin-top: 1rem;
}
#root .accordion-content-example:first-child {
  margin-top: 1rem;
}

#root .accordion-content-title {
  margin-top: 1rem;
  color: rgba(0, 0, 0, 0.7);
  margin-bottom: 0.25rem;
}

#root .full-width {
  width: 100%;
  text-align: start;
}

/* RIGHT ALIGNED LANGUAGES */

#root .right-aligned-lang .synonym {
  text-align: right;
}

#root .right-aligned-lang .translation-icon {
  margin-left: 0.2em;
  margin-right: 0;
}

#root .right-aligned-lang .foreign-lemma {
  direction: rtl;
  text-align: right;
  float: right;
}

#root .right-aligned-lang .leftColumnStyle {
  direction: ltr;
  text-align: left;
}

#root ol li:before {
  content: '♦';
  font-size: 0.67rem;
  margin-right: 3px;
}

#root ol .rightColumnStyle:before {
  content: '';
}

#root .right-aligned-lang .rightColumnStyle {
  direction: rtl;
  text-align: right;
}

/* START PAGE */

#root .start-page .card-footer {
  background: white;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
}

/* SUGGESTIONS */

#root .suggestions .list > div {
  line-height: 1.14285714rem;
}

#root .suggestions .header {
  font-weight: bold;
  font-size: 0.9rem;
}

#root .suggestions .translated-suggestions {
  font-size: 1rem;
  text-align: right;
  width: 100%;
}

#root .suggestions .list {
  display: flex;
}

ol {
  list-style: none;
}

#root .suggestions .list .item {
  color: #078;
  cursor: pointer;
  padding-right: 0.5rem;
  white-space: nowrap;
}

/* INFLEXIONS */

#root .searchWord {
  background: #ff9;
  color: #444;
}

#root .lemma-content .references {
  margin-top: 0.75rem;
}

#root .lemma-content .inflexions,
#root .lemma-content .references {
  color: #666;
  padding-top: 0.3em;
}

/* LEMMA CONTENT */

#root .loader-segment {
  background: none;
  border: 0;
  padding-top: 1rem;
  padding-bottom: 4rem;
}

#root .loader-segment .spinner-border {
  color: #078;
  height: 55px;
  width: 55px;
}

#root .alternate-lemma {
  padding-left: 0.4em;
}

#root .usage {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.spacer {
  padding-left: 0.4em;
}

.word-type-dot {
  height: 15px;
  width: 15px;
  margin-left: 0.4em;
  border-radius: 50%;
  display: inline-block;
}

/* DELETE? CANT FIND WHERE ITS USED */
.wordType {
  color: #666;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 0.4em;
  display: inline-block;
}

#root .translation-icon {
  margin-right: 0.1em;
  font-size: 80%;
}

#root .lemma-content .ART {
  color: #777;
  font-weight: 400;
  font-size: 1rem;
  padding-right: 0.4em;
  display: inline-block;
}

#root .rightColumnStyle {
  text-align: right;
}

#root .lemma-content .definition {
  color: black;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
}

#root .lemma-content .Type {
  color: #666;
  font-weight: 400;
  font-size: 1rem;
  padding-left: 0.4em;
  display: inline-block;
}

#root .lemma-content .header {
  margin: 0;
  margin-bottom: -4px;
  font-size: 1rem;
}

#root .lemma-content .card-title .swedish-lemma,
#root .lemma-content .card-title .foreign-lemma {
  display: inline-block;
}

#root .lemma-content .header .foreign-lemma {
  line-height: 1;
}

#root .lemma-content .speak-word .icon {
  color: #078;
  opacity: 0.7;
}

#root .lemma-content .speak-word:hover .icon {
  opacity: 1;
}

#root .lemma-content .list {
  margin-top: 0.15rem;
  margin-bottom: 0.15rem;
  color: #777;
}

#root .lemma-content .title {
  margin-bottom: 0.5rem;
  margin-top: 0;
}

#root .lemma-content .speak-word,
#root .lemma-content .speak-word:hover,
#root .lemma-content .speak-word:focus {
  background: none;
}

#root .lemma-content .speak-word {
  position: relative;
  top: -0.5rem;
  left: 0;
  padding-right: 0;
  font-size: 75%;
  margin-right: 0;
  padding-top: 0;
  padding-bottom: 0;
}

/* SEARCH */

#root .search-dictionary {
  cursor: pointer;
  width: 100%;
  padding-bottom: 0.5rem;
  padding-top: 1rem;
}

/* MESSAGE (WARNING) */

#root .message {
  margin-top: 0;
  font-size: 1rem;
}

#root #react-root .message .icon {
  font-size: 1rem;
}

/* LANGUAGE MENU */

#root .hidden-language-padding {
  padding-bottom: 1.1rem;
}

#root .language-menu-container .language-menu-dropdown .menu {
  max-height: 150px;
  overflow-y: scroll;
  left: -4.5rem;
}

#root .language-menu-container {
  padding-top: 0;
  margin-top: 0;
  padding-bottom: 1.1rem;
  font-size: var(--font-size__small);
  text-align: right;
}

#root .language-menu-container .language-menu-dropdown .card-text {
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: var(--font-size__small);
}

#root
  .language-menu-container
  .language-menu-dropdown
  .text
  .chosen-language-label {
  color: rgb(0, 119, 136);
}

#root .language-menu-container .icon:before {
  padding-right: 0.25rem;
  padding-left: 0.25rem;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.4);
  margin: 0;
}

#root .language-menu-container .icon {
  margin: 0;
}

#root .language-menu-container .language-menu-dropdown .search {
  z-index: -1;
  max-width: 0;
}

/* RESPONSIVE */

@media screen and (max-width: 300px) {
  #root .accordion .column {
    width: 100%;
  }

  #root .rightColumnStyle {
    text-align: left !important;
  }
}

@media screen and (max-width: 450px) {
  #root .lemma-counter {
    left: -4px;
    margin-right: -3px;
  }

  #root .lemma-content .swedish-lemma {
    margin-left: 0.5px;
    padding-right: 0.3em;
  }

  #root .Type {
    margin-right: 0;
  }
}

/* ANIMATION */
@keyframes opacity-animation {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}
